window.TrelloPowerUp.initialize(
  {
    "board-buttons": function (t, opts) {
      return [
        {
          icon: "https://files.softicons.com/download/business-icons/mono-business-icons-by-custom-icon-design/ico/target.ico",
          text: process.env.POWERUP_NAME,
          condition: "edit",
          callback: function (t) {
            console.log("Jelly!");
          },
        },
      ];
    },
  }
  //   {
  //     appKey: process.env.POWERUP_KEY,
  //     appName: "Goals for Trello",
  //     appAuthor: "Native Power-Ups",
  //   }
);
